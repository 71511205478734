import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from 'src/hooks/auth'

import { LoadingPage } from '../../../pages/LoadingPage'
import { Footer } from './Footer'
import { Header } from './Header'
import { Container, Wrapper } from './styles'

export const AppLayout: React.FC = () => {
  const { isAuthenticated, isLoaded } = useAuth()

  if (!isLoaded) {
    return <LoadingPage />
  }

  if (!isAuthenticated) {
    localStorage.setItem(
      `${globalThis.storageKey}:redirectTo`,
      window.location.pathname
    )
    return <Navigate to="/login" />
  }

  return (
    <Container>
      <Header />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Footer />
    </Container>
  )
}
