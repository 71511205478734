import styled from 'styled-components'

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6rem;
  z-index: 10;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--brand-light);
  box-shadow: var(--shadow);
  padding: 0 3.2rem;

  .logo {
    display: flex;

    height: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      height: 100%;
      max-height: 3.2rem;
    }
  }
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  height: 100%;

  > ul {
    display: flex;
    list-style: none;
    height: 100%;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }

    li {
      height: 100%;
      opacity: 0.5;
      transition: all 0.2s;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 1.6rem;
        border-bottom: 0.2rem solid transparent;
        color: var(--brand);
        transition: all 0.2s;
      }

      &.active {
        opacity: 1;

        a {
          border-bottom: 0.2rem solid var(--brand);
          color: var(--brand);
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.active {
    > ul {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--brand-light);
      flex-direction: column;

      li {
        height: fit-content;
        width: 100%;

        a {
          padding: 2.4rem 0;
          border: none;
          font-size: 2rem;
        }
        &.active {
          a {
            border: none;
            font-weight: bold;
          }
        }
      }
    }
  }
`

export const ExpandMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: var(--brand);
  font-size: 3.2rem;
  z-index: 10;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`
