import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const Container = styled(Link)`
  display: block;
  margin-top: 1.6rem;
  font-size: 1.6rem;
  color: var(--brand);
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.1rem;

  &:hover {
    color: var(--brand-dark);
  }
`
