import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    font-size: 3.2rem;
    color: var(--headline);
    text-align: center;
    margin-top: 3.2rem;
    font-weight: 500;
  }

  iframe {
    width: 90vw;
    margin: 3.2rem auto;
    max-width: 70rem;
    height: 100%;
    min-height: 50vh;
    max-height: 80vh;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 0.8rem 1.6rem;
    border: 1px solid var(--gray-light);
    box-shadow: var(--shadow-card);
  }
`
