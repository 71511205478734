import br from './languages/br.json'
import en from './languages/en.json'

export type Language = 'br' | 'en'
export type TextKey = keyof typeof br | keyof typeof en

export const i18n = (language: Language, key: TextKey): string => {
  const main = { br, en }

  return main[language][key]
}

export const formatLanguage = (languageVariation: string): string => {
  languageVariation = languageVariation.toLowerCase()

  const languageVariations = {
    br: ['pt', 'br', 'pt-br', 'pt-pt'],
    en: ['en', 'en-us', 'en-gb'],
  }

  const language = (Object.keys(languageVariations) as Language[]).find(
    (language: Language) => {
      return languageVariations[language].includes(languageVariation)
    }
  )

  return language || 'en'
}
