import React from 'react'

import { CircleLoading } from '../../ui/loading/CircleLoading'
import { Container } from './styles'

interface IOwnProps {
  children: React.ReactNode
  loading?: boolean
  color?: string
  format?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'secondary'
}

type IButtonProps = IOwnProps & JSX.IntrinsicElements['button']

export const Button: React.FC<IButtonProps> = ({
  ref: _ref,
  children,
  loading,
  disabled,
  color = 'brand',
  format = 'medium',
  variant = 'primary',
  ...rest
}) => {
  return (
    <Container
      format={format}
      variant={variant}
      color={color}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <CircleLoading size="3.2rem" /> : children}
    </Container>
  )
}
