import styled from 'styled-components'

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: var(--shadow-card);
  width: 90vw;
  padding: 1.6rem;
  border-radius: 0.8rem;

  gap: 1.6rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: var(--gray-light);
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      opacity: 0.7;
    }
  }
`

export const Roadmap = styled.div`
  display: flex;

  .checkpoint {
    flex: 1;
    display: flex;
    align-items: center;
    color: var(--brand-light);

    &.active {
      color: var(--brand);
    }
  }

  .checkpoint:first-child {
    width: 3.2rem;
    max-width: 3.2rem;

    .line {
      display: none;
    }
  }

  .line {
    flex: 1;
    height: 0.2rem;
    background-color: currentColor;
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background-color: currentColor;
    cursor: default;

    p {
      color: var(--brand-light-02);
    }
  }
`
