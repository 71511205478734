import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'src/hooks/auth'

import { LoadingPage } from '../LoadingPage'

export const Logout: React.FC = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    logout()
    navigate('/login')
  }, [logout, navigate])

  return <LoadingPage />
}
