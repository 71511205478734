import { Container } from './styles'

interface IRowProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

export const Row: React.FC<IRowProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>
}
