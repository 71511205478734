import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { useAuth } from 'src/hooks/auth'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import { Container, MenuList } from './styles'

const ProfileOptions: React.FC = () => {
  const { user } = useAuth()
  const [menuActive, setMenuActive] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const avatarUrl = useMemo(() => {
    if (user?.avatarUrl) {
      return formatImgUrl(user.avatarUrl)
    }
    return ''
  }, [user])

  const initials = useMemo(() => {
    const names = user?.name?.split(' ')
    const firstName = names[0]
    const lastName = names[names.length - 1]

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }
    return ''
  }, [user])

  useEffect(() => {
    function handleClickOut(e: MouseEvent) {
      if (!e.composedPath().some(el => el === buttonRef.current)) {
        setTimeout(() => {
          setMenuActive(false)
        }, 100)
        setMenuActive(false)
      }
    }

    if (menuActive) {
      window.addEventListener('mouseup', handleClickOut)
    } else {
      window.removeEventListener('mouseup', handleClickOut)
    }

    return () => {
      window.removeEventListener('mouseup', handleClickOut)
    }
  }, [menuActive])

  return (
    <Container>
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setMenuActive(!menuActive)}
      >
        <span>{user?.name}</span>
        <div className="avatar">
          {user.avatarUrl ? <img src={avatarUrl} alt={user.name} /> : initials}
        </div>

        <FiChevronDown />
      </button>

      <MenuList active={menuActive}>
        <li>
          <Link to="/portal-switch">Trocar de portal</Link>
        </li>
        <li>
          <Link to="/profile">Perfil</Link>
        </li>
        <li>
          <Link to="/logout">Sair</Link>
        </li>
      </MenuList>
    </Container>
  )
}

export default ProfileOptions
