import styled from 'styled-components'

export const Container = styled.div`
  max-width: 90vw;
  margin: 0 auto;

  h3 {
    font-size: 3.2rem;
    font-weight: 500;
    color: var(--headline);
    margin-bottom: 1.6rem;
  }

  .content {
    max-width: 90vw;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 1.6rem;
  }
`
