import { ICardOptions } from 'src/interfaces/ICardOptions'

import { CardItem } from '../CardItem'
import { Container } from './styles'

interface ICardListProps {
  title: string
  items?: ICardOptions[]
}

export const CardList: React.FC<ICardListProps> = ({ title, items = [] }) => {
  return (
    <Container>
      <h3>{title}</h3>
      <div className="content">
        {items.map(card => {
          return <CardItem key={card.id} card={card} />
        })}
      </div>
    </Container>
  )
}
