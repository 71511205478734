import React from 'react'

import { Container } from './styles'

interface ILinkProps {
  to: string
  children: React.ReactNode
}

export const Link: React.FC<ILinkProps> = ({ to, children }) => {
  return <Container to={to}>{children}</Container>
}
