import styled from 'styled-components'

export const Container = styled.div`
  margin: 3.2rem auto;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatar-update {
    width: 20rem;
    height: 20rem;
    margin-bottom: 2.4rem;
    border-radius: 50%;
    background: var(--brand-light);
    border: 0.4rem solid var(--brand);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .preview {
      font-size: 4.8rem;
      color: var(--brand);
      letter-spacing: 0.1rem;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
      }
    }

    input {
      display: none;
    }
  }

  .forms {
    width: 100%;
    display: flex;
    gap: 3.2rem;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      flex-direction: column;
    }

    form {
      display: flex;
      flex-direction: column;

      background-color: var(--white);
      box-shadow: var(--shadow);
      padding: 3.2rem;
      border-radius: 0.8rem;
      height: fit-content;
    }

    .profile-form {
      flex: 2;
    }

    .password-form {
      flex: 1;
    }
  }

  h1 {
    font-size: 3.2rem;
    color: var(--headline);
    font-weight: 500;
    text-align: center;
    margin-bottom: 4rem;
  }
  h2 {
    color: var(--headline);
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.6rem;
  }
`
