import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FiAlertTriangle } from 'react-icons/fi'

import { Container } from './styles'

interface IOwnProps {
  name: string
  label: string
  max?: number
  min?: number
  maxLength?: number
  format?: RegExp | string | ((value: string) => string)
  valueAsNumber?: boolean
  valueAsDate?: boolean
  hoverToShowError?: boolean
  labelStyle?: React.CSSProperties
}

type IInputProps = IOwnProps & JSX.IntrinsicElements['input']

export const Input: React.FC<IInputProps> = ({
  name,
  label,
  required,
  disabled,
  valueAsNumber,
  valueAsDate,
  labelStyle,
  format,
  type = 'text',
  hoverToShowError = true,
  pattern,
  ...rest
}) => {
  const {
    register,
    formState: { errors, dirtyFields },
  } = useFormContext()

  function getError(inputName: string) {
    if (inputName.includes('.')) {
      let err = errors
      const path = inputName.split('.')
      for (let i = 0; i < path.length; i++) {
        if (err) {
          err = err[path[i]]
        }
      }

      return err
    }

    return errors[inputName]
  }

  function getDirtyField(inputName: string) {
    if (inputName.includes('.')) {
      let dirtyFi = dirtyFields
      const path = inputName.split('.')
      for (let i = 0; i < path.length; i++) {
        if (dirtyFi) {
          dirtyFi = dirtyFi[path[i]]
        }
      }

      return dirtyFi
    }
    return dirtyFields[inputName]
  }

  const inputRegisterOptions = useMemo(() => {
    const inputOptions = {
      required,
      disabled,
      valueAsNumber,
      valueAsDate,
    }

    return inputOptions
  }, [required, disabled, valueAsNumber, valueAsDate])

  function handleOnInput(e: React.FormEvent<HTMLInputElement>) {
    if (format) {
      if ((typeof format === 'string' || format instanceof RegExp) && pattern) {
        e.currentTarget.value = e.currentTarget.value.replace(format, pattern)
      } else if (typeof format === 'function') {
        e.currentTarget.value = format(e.currentTarget.value)
      }
    }
  }

  return (
    <Container
      htmlFor={rest.id}
      hasError={!!getError(name)}
      isDirty={getDirtyField(name)}
      type={type}
      style={labelStyle}
    >
      <strong>
        {label} {required && <span>*</span>}
      </strong>
      <input
        type={type}
        {...rest}
        required={required}
        disabled={disabled}
        {...register(name, { ...inputRegisterOptions })}
        onInput={handleOnInput}
      />
      {hoverToShowError && getError(name) && (
        <span className="info-error">
          <FiAlertTriangle />
          <span>{getError(name)?.message}</span>
        </span>
      )}
      {!hoverToShowError && getError(name) && (
        <span className="error">{getError(name).message}</span>
      )}
    </Container>
  )
}
