import styled, { css } from 'styled-components'

import { lighten } from 'polished'

interface IContainerProps {
  hasError: boolean
  isDirty: boolean
  type: string
}

export const Container = styled.label<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.4rem;
  margin-bottom: 1.6rem;
  min-height: 7rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  flex: 2;
  position: relative;

  span {
    color: var(--red);
  }

  input {
    width: 100%;
    height: 4.8rem;
    border: 0.2rem solid var(--brand-light);
    background-color: var(--brand-light-02);
    border-radius: 0.8rem;
    padding: 0 1.6rem;

    &::placeholder {
      color: var(--paragraph);
      opacity: 0.4;
    }

    &:focus {
      outline-color: var(--brand-dark);
    }

    ${({ isDirty }) =>
      isDirty &&
      css`
        border-color: var(--brand);
      `}

    ${({ hasError }) =>
      hasError &&
      css`
        border-color: var(--danger);
      `}

    &:read-only {
      border: 0.2rem solid var(--brand-light);
      background-color: var(--brand-light-02);
      outline-color: var(--brand-light);
    }
  }

  .error {
    color: var(--danger);
  }

  .info-error {
    position: absolute;
    color: var(--danger);
    bottom: 2.4rem;
    right: 1.6rem;
    font-size: 2rem;
    transform: translateY(50%);
    display: flex;

    span {
      display: none;
      align-items: center;
      position: absolute;
      right: 3.2rem;
      top: -0.8rem;
      width: max-content;
      max-width: 15rem;
      max-height: max-content;

      font-size: 1.4rem;
      background-color: ${({ theme }) => lighten(0.5, theme.colors.danger)};
      padding: 0.8rem 1.6rem;
      border-radius: 0.8rem;
      border: 0.2rem solid var(--danger);

      &::before {
        content: '';
        position: absolute;
        top: 1.2rem;
        right: 0.3rem;
        width: 0.8rem;
        height: 0.8rem;
        background-color: ${({ theme }) => lighten(0.5, theme.colors.danger)};
        border-bottom: 0.2rem solid var(--danger);
        border-right: 0.2rem solid var(--danger);
        transform: translateX(100%) rotate(-45deg);
        pointer-events: none;
      }
    }

    &:hover {
      span {
        display: flex;
      }
    }
  }

  ${props =>
    props.type === 'checkbox' &&
    css`
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 1.6rem 0;

      input {
        width: 1.6rem;
        height: 1.6rem;
      }
    `}
`
