import { useEffect, useState } from 'react'

import { Banner, IBannerData } from 'src/components/ui/Banner'
import { CardList } from 'src/components/ui/CardList'
import { useAppVariables } from 'src/hooks/appVariables'
import { IBanner } from 'src/interfaces/IBanner'
import { ICardOptions } from 'src/interfaces/ICardOptions'
import { ICategory } from 'src/interfaces/ICategory'
import { IService } from 'src/interfaces/IService'
import { IShortcut } from 'src/interfaces/IShortcut'
import { api } from 'src/services/api'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import { Container } from './styles'

export const Home: React.FC = () => {
  const { portalId, setCurrentPage, setPageTitle, portal } = useAppVariables()
  const [banners, setBanners] = useState<IBannerData[]>([])
  const [shortcuts, setShortcuts] = useState<ICardOptions[]>([])
  const [categories, setCategories] = useState<ICardOptions[]>([])
  const [services, setServices] = useState<ICardOptions[]>([])

  useEffect(() => {
    if (portalId) {
      api
        .get<IBanner[]>(`portal/${portalId}/banners`)
        .then(({ data }) => {
          setBanners(
            data.map(banner => {
              banner.imageUrl = formatImgUrl(banner.imageUrl)

              return {
                id: banner.bannerId,
                title: banner.title || '',
                text: banner.text || '',
                imageUrl: banner.imageUrl,
                fillType: banner.fillType,
                mainColor: banner.backgroundColor,
                shadowColor: banner.shadowColor,
                button: banner.button,
                position: banner.position,
              }
            })
          )
        })
        .catch(() => {
          setBanners([])
        })

      api
        .get<IShortcut[]>(`portal/${portalId}/shortcuts`)
        .then(({ data }) => {
          setShortcuts(
            data.map(shortcut => {
              const shortcutDef = {
                id: shortcut.shortcutId,
                title: '',
                text: '',
                iconUrl: formatImgUrl('/defaults/service-icon.png'),
                mainColor: 'rgba(16, 24, 32, 0.314)',
                accentColor: '#ffffff',
                link: '',
              }

              if (shortcut.service) {
                shortcutDef.title = shortcut.service.name
                shortcutDef.text = shortcut.service.description
                shortcutDef.iconUrl = formatImgUrl(shortcut.service.iconUrl)
                shortcutDef.mainColor = shortcut.service.mainColor
                shortcutDef.accentColor = shortcut.service.accentColor
                shortcutDef.link = `/service/${shortcut.service.slug}`
              } else if (shortcut.category) {
                shortcutDef.title = shortcut.category.name
                shortcutDef.text = shortcut.category.description
                shortcutDef.iconUrl = formatImgUrl(shortcut.category.iconUrl)
                shortcutDef.mainColor = shortcut.category.mainColor
                shortcutDef.accentColor = shortcut.category.accentColor
                shortcutDef.link = `/category/${shortcut.category.slug}`
              }

              return shortcutDef
            })
          )
        })
        .catch(() => {
          setShortcuts([])
        })

      api.get<ICategory[]>(`portal/${portalId}/categories`).then(({ data }) => {
        setCategories(
          data.map(category => {
            return {
              id: category.categoryId,
              title: category.name,
              text: category.description,
              iconUrl: formatImgUrl(category.iconUrl),
              mainColor: category.mainColor,
              accentColor: category.accentColor,
              link: `/category/${category.slug}`,
            }
          })
        )
      })

      api
        .get<IService[]>(`portal/${portalId}/services`)
        .then(({ data }) => {
          setServices(
            data.map(service => {
              return {
                id: service.serviceId,
                title: service.name,
                text: service.description,
                iconUrl: formatImgUrl(service.iconUrl),
                mainColor: service.mainColor,
                accentColor: service.accentColor,
                link: `/service/${service.slug}`,
              }
            })
          )
        })
        .catch(() => {
          setServices([])
        })
    }
  }, [portalId])

  useEffect(() => {
    setCurrentPage('catalog')

    if (portal?.name) {
      setPageTitle(`Catálogo de Serviços - ${portal.name}`)
    } else {
      setPageTitle(`Catálogo de Serviços`)
    }
  }, [portal, setCurrentPage, setPageTitle])

  return (
    <Container>
      <Banner
        banners={banners}
        isSearchInsideDefaultBanner
        isSearchInsideBanner
      />

      {shortcuts.length > 0 && (
        <CardList title="Meus Atalhos" items={shortcuts} />
      )}
      {categories.length > 0 && (
        <CardList title="Categorias" items={categories} />
      )}
      {services.length > 0 && (
        <CardList title="Outros Serviços" items={services} />
      )}

      {categories.length === 0 && services.length === 0 && (
        <p className="empty-message">
          Nenhuma categoria ou serviço foi cadastrado para este portal.
        </p>
      )}
    </Container>
  )
}
