import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: var(--brand);
    gap: 0.8rem;

    span {
      @media (max-width: ${props => props.theme.breakpoints.md}) {
        display: none;
      }

      margin-right: 0.8rem;
    }

    .avatar {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      border: 0.3rem solid var(--brand);
      background-color: var(--brand-light-02);

      letter-spacing: 0.1rem;
      font-weight: bold;
      color: var(--brand);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
      }
    }
  }
`

interface IMenuListProps {
  active: boolean
}

export const MenuList = styled.ul<IMenuListProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5rem;
  right: 0;
  border-radius: 0.8rem;
  width: 20rem;
  background-color: var(--brand-light-02);
  border: 0.2rem solid var(--brand);
  overflow: hidden;

  a {
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--brand);
    padding: 0.8rem 1.6rem;
    transition: all 0.2s;

    &:hover {
      background-color: var(--brand-light);
    }
  }

  ${props =>
    !props.active &&
    css`
      display: none;
    `}
`
