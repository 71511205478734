import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Banner, IBannerData } from 'src/components/ui/Banner'
import { CardList } from 'src/components/ui/CardList'
import { useAppVariables } from 'src/hooks/appVariables'
import { ICardOptions } from 'src/interfaces/ICardOptions'
import { ICategory } from 'src/interfaces/ICategory'
import { IService } from 'src/interfaces/IService'
import { api } from 'src/services/api'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import { Container } from './styles'

export const Category: React.FC = () => {
  const { slug } = useParams()
  const { portalId, portal, setCurrentPage, setPageTitle } = useAppVariables()
  const [category, setCategory] = useState<IBannerData>()
  const [services, setServices] = React.useState<ICardOptions[]>([])
  const [subCategories, setSubCategories] = React.useState<ICardOptions[]>([])

  useEffect(() => {
    api
      .get<ICategory>(`/portal/${portalId}/categories/${slug}`)
      .then(({ data }) => {
        data.iconUrl = formatImgUrl(data.iconUrl)
        setCategory({
          id: data.categoryId,
          title: data.name,
          text: data.description,
          imageUrl: data.coverUrl,
          fillType: data.fillType,
          mainColor: data.mainColor,
        })
      })

    api
      .get<IService[]>(`/portal/${portalId}/services?parentId=${slug}`)
      .then(({ data }) => {
        setServices(
          data.map(service => ({
            id: service.serviceId,
            title: service.name,
            text: service.description,
            iconUrl: formatImgUrl(service.iconUrl),
            link: `/service/${service.slug}`,
            mainColor: service.mainColor,
            accentColor: service.accentColor,
          }))
        )
      })
    api
      .get<ICategory[]>(`/portal/${portalId}/categories?parentId=${slug}`)
      .then(({ data }) => {
        setSubCategories(
          data.map(category => ({
            id: category.categoryId,
            title: category.name,
            text: category.description,
            iconUrl: formatImgUrl(category.iconUrl),
            link: `/category/${category.slug}`,
            mainColor: category.mainColor,
            accentColor: category.accentColor,
          }))
        )
      })
  }, [slug, portalId])

  useEffect(() => {
    setCurrentPage('catalog')

    if (category?.title && portal?.name) {
      setPageTitle(`${category.title} - ${portal.name}`)
    } else if (category?.title && !portal?.name) {
      setPageTitle(`${category.title}`)
    } else if (!category?.title && portal?.name) {
      setPageTitle(`${portal.name}`)
    } else {
      setPageTitle('Request Portal')
    }
  }, [category, setPageTitle, portal, setCurrentPage])

  return (
    <Container>
      <Banner
        banners={(!!category && [category]) || []}
        isSearchInsideBanner
        isSearchInsideDefaultBanner
      />

      {services.length > 0 && <CardList title="Serviços" items={services} />}
      {subCategories.length > 0 && (
        <CardList title="Sub-categorias" items={subCategories} />
      )}
    </Container>
  )
}
