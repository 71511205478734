import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from 'src/components/inputs/Button'
import { Input } from 'src/components/inputs/Input'
import { Row } from 'src/components/ui/Row'
import { useAppVariables } from 'src/hooks/appVariables'
import { useAuth } from 'src/hooks/auth'
import { formatImgUrl } from 'src/utils/formatImgUrl'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Container } from './styles'

interface IFormData {
  email: string
  name: string
  phone: string
}

interface IFormPasswordData {
  oldPassword: string
  password: string
  confirmPassword: string
}

const dataSchema = yup.object({
  email: yup.string().email().required(),
  name: yup.string().required(),
  phone: yup.string(),
})

const passSchema = yup.object({
  oldPassword: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não conferem')
    .required(),
})

export const Profile: React.FC = () => {
  const { portalId } = useAppVariables()
  const { user, updateUser, updateUserPassword, updateUserAvatar } = useAuth()
  const dataMethods = useForm<IFormData>({ resolver: yupResolver(dataSchema) })
  const passMethods = useForm<IFormPasswordData>({
    resolver: yupResolver(passSchema),
  })
  const [preview, setPreview] = React.useState<string | null>(null)

  async function onSubmitData(input: IFormData) {
    await updateUser(portalId, input)
  }

  async function onSubmitPass(input: IFormPasswordData) {
    await updateUserPassword(portalId, input)
  }

  async function handleSubmitAvatar(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target

    if (files && files[0]) {
      const file = files[0]

      updateUserAvatar(portalId, file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (reader.result) {
          setPreview(reader.result as string)
        }
      }
    } else {
      setPreview(null)
    }
  }

  const initials = useMemo(() => {
    const name = user.name.split(' ')

    return name[0][0] + name[name.length - 1][0]
  }, [user])

  useEffect(() => {
    if (user) {
      dataMethods.reset({
        email: user.email,
        name: user.name,
        phone: user.phone,
      })

      if (user.avatarUrl) {
        setPreview(formatImgUrl(user.avatarUrl))
      }
    }
  }, [dataMethods, user])

  return (
    <Container>
      <h1>Meu perfil</h1>

      <label className="avatar-update">
        <div className="preview">
          {preview ? <img src={preview} alt={user.name} /> : initials}
        </div>

        <input type="file" accept="image/*" onChange={handleSubmitAvatar} />
      </label>

      <div className="forms">
        <FormProvider {...dataMethods}>
          <form
            className="profile-form"
            onSubmit={dataMethods.handleSubmit(onSubmitData)}
          >
            <h2>Atualizar perfil</h2>
            <Row>
              <Input label="Nome" name="name" required />
              <Input label="Telefone" name="phone" />
            </Row>
            <Row>
              <Input label="E-mail" name="email" required readOnly />
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button type="submit" style={{ maxWidth: '25rem' }}>
                Atualizar Perfil
              </Button>
            </Row>
          </form>
        </FormProvider>

        <FormProvider {...dataMethods}>
          <form
            className="password-form"
            onSubmit={passMethods.handleSubmit(onSubmitPass)}
            autoComplete="off"
          >
            <h2>Atualizar senha</h2>
            <Input label="Senha antiga" name="current-password" required />
            <Input label="Nova senha" name="new-password" required />
            <Input
              label="Confirmar a nova senha"
              name="confirm-password"
              required
            />

            <Button type="submit">Atualizar Senha</Button>
          </form>
        </FormProvider>
      </div>
    </Container>
  )
}
