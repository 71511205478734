import { createGlobalStyle, keyframes } from 'styled-components'

import { darken, lighten, transparentize } from 'polished'

const skeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 60%);
  }

  100% {
    background-color: hsl(200, 20%, 80%);
  }
`

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    font-size: 62.5%;

    --brand-dark: ${props => props.theme.colors.brandDark};
    --brand: ${props => props.theme.colors.brand};
    --brand-light: ${props => props.theme.colors.brandLight};
    --brand-light-02: ${props => props.theme.colors.brandLight02};

    --success-dark: ${props => darken(0.1, props.theme.colors.success)};
    --success: ${props => props.theme.colors.success};
    --success-light: ${props => lighten(0.1, props.theme.colors.success)};
    --warning-dark: ${props => darken(0.1, props.theme.colors.warning)};
    --warning: ${props => props.theme.colors.warning};
    --warning-light: ${props => lighten(0.1, props.theme.colors.warning)};
    --danger-dark: ${props => darken(0.1, props.theme.colors.danger)};
    --danger: ${props => props.theme.colors.danger};
    --danger-light: ${props => lighten(0.1, props.theme.colors.danger)};

    --headline: ${props => props.theme.colors.headline};
    --paragraph: ${props => props.theme.colors.paragraph};
    --white: ${props => props.theme.colors.white};
    --black: ${props => props.theme.colors.black};
    --black-opacity: ${props => transparentize(0.8, props.theme.colors.black)};

    --red-dark: ${props => props.theme.colors.redDark};
    --red-dark-02: ${props => props.theme.colors.redDark02};
    --red: ${props => props.theme.colors.red};
    --red-light: ${props => props.theme.colors.redLight};
    --red-light-02: ${props => props.theme.colors.redLight02};

    --pink-dark: ${props => props.theme.colors.pinkDark};
    --pink-dark-02: ${props => props.theme.colors.pinkDark02};
    --pink: ${props => props.theme.colors.pink};
    --pink-light: ${props => props.theme.colors.pinkLight};
    --pink-light-02: ${props => props.theme.colors.pinkLight02};

    --purple-dark: ${props => props.theme.colors.purpleDark};
    --purple-dark-02: ${props => props.theme.colors.purpleDark02};
    --purple: ${props => props.theme.colors.purple};
    --purple-light: ${props => props.theme.colors.purpleLight};
    --purple-light-02: ${props => props.theme.colors.purpleLight02};

    --indigo-dark: ${props => props.theme.colors.indigoDark};
    --indigo-dark-02: ${props => props.theme.colors.indigoDark02};
    --indigo: ${props => props.theme.colors.indigo};
    --indigo-light: ${props => props.theme.colors.indigoLight};
    --indigo-light-02: ${props => props.theme.colors.indigoLight02};

    --blue-dark: ${props => props.theme.colors.blueDark};
    --blue-dark-02: ${props => props.theme.colors.blueDark02};
    --blue: ${props => props.theme.colors.blue};
    --blue-light: ${props => props.theme.colors.blueLight};
    --blue-light-02: ${props => props.theme.colors.blueLight02};

    --sky-dark: ${props => props.theme.colors.skyDark};
    --sky-dark-02: ${props => props.theme.colors.skyDark02};
    --sky: ${props => props.theme.colors.sky};
    --sky-light: ${props => props.theme.colors.skyLight};
    --sky-light-02: ${props => props.theme.colors.skyLight02};

    --cyan-dark: ${props => props.theme.colors.cyanDark};
    --cyan-dark-02: ${props => props.theme.colors.cyanDark02};
    --cyan: ${props => props.theme.colors.cyan};
    --cyan-light: ${props => props.theme.colors.cyanLight};
    --cyan-light-02: ${props => props.theme.colors.cyanLight02};

    --green-dark: ${props => props.theme.colors.greenDark};
    --green-dark-02: ${props => props.theme.colors.greenDark02};
    --green: ${props => props.theme.colors.green};
    --green-light: ${props => props.theme.colors.greenLight};
    --green-light-02: ${props => props.theme.colors.greenLight02};

    --lime-dark: ${props => props.theme.colors.limeDark};
    --lime-dark-02: ${props => props.theme.colors.limeDark02};
    --lime: ${props => props.theme.colors.lime};
    --lime-light: ${props => props.theme.colors.limeLight};
    --lime-light-02: ${props => props.theme.colors.limeLight02};

    --yellow-dark: ${props => props.theme.colors.yellowDark};
    --yellow-dark-02: ${props => props.theme.colors.yellowDark02};
    --yellow: ${props => props.theme.colors.yellow};
    --yellow-light: ${props => props.theme.colors.yellowLight};
    --yellow-light-02: ${props => props.theme.colors.yellowLight02};

    --orange-dark: ${props => props.theme.colors.orangeDark};
    --orange-dark-02: ${props => props.theme.colors.orangeDark02};
    --orange: ${props => props.theme.colors.orange};
    --orange-light: ${props => props.theme.colors.orangeLight};
    --orange-light-02: ${props => props.theme.colors.orangeLight02};

    --gray-dark: ${props => props.theme.colors.grayDark};
    --gray-dark-02: ${props => props.theme.colors.grayDark02};
    --gray: ${props => props.theme.colors.gray};
    --gray-light: ${props => props.theme.colors.grayLight};
    --gray-light-02: ${props => props.theme.colors.grayLight02};

    --small: ${props => props.theme.fontSizes.small};
    --medium: ${props => props.theme.fontSizes.medium};
    --large: ${props => props.theme.fontSizes.large};
    --h4: ${props => props.theme.fontSizes.h4};
    --h3: ${props => props.theme.fontSizes.h3};
    --h2: ${props => props.theme.fontSizes.h2};
    --h1: ${props => props.theme.fontSizes.h1};

    --shadow: ${props => props.theme.shadows.default};
    --shadow-card: ${props => props.theme.shadows.card};

    --xs: ${props => props.theme.breakpoints.xs};
    --sm: ${props => props.theme.breakpoints.sm};
    --md: ${props => props.theme.breakpoints.md};
    --lg: ${props => props.theme.breakpoints.lg};
    --xl: ${props => props.theme.breakpoints.xl};
    --xxl: ${props => props.theme.breakpoints.xxl};
  }

  body {
    background: var(--brand-light-02);
    color: var(--paragraph);
  overflow: overlay;

  }

  body,
  input,
  textarea,
  select,
  button {
    font: 400 1.6rem 'DM Sans', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ::-webkit-scrollbar {
    width: 0.8rem;
    border-radius: 0.8rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0.8rem;
    background: var(--brand-light-02);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--brand-light);
    border-radius: 0.8rem;
    transition: all 0.3s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    width: 0.8rem;
    background: var(--brand);
  }

  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skeleton-img {
    animation: ${skeletonLoading} 1s linear infinite alternate;
  }
`
