import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ICardOptions } from 'src/interfaces/ICardOptions'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import { Container } from './styles'

interface ICardItemProps {
  card: ICardOptions
}

export const CardItem: React.FC<ICardItemProps> = ({ card }) => {
  const navigate = useNavigate()

  const image = useMemo(() => {
    return (
      formatImgUrl(card.iconUrl) || formatImgUrl('/defaults/service-icon.png')
    )
  }, [card.iconUrl])

  function handleClick() {
    if (card.link) {
      navigate(card.link)
    }
  }

  return (
    <Container
      onClick={handleClick}
      mainColor={card.mainColor}
      accentColor={card.accentColor}
    >
      <img src={image} alt={card.title} />
      <div className="card-texts">
        <h4>{card.title}</h4>
        {card.text && <p>{card.text}</p>}
      </div>
    </Container>
  )
}
