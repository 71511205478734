import { Navigate, Outlet } from 'react-router-dom'

import { useAppVariables } from 'src/hooks/appVariables'
import { useAuth } from 'src/hooks/auth'
import { LoadingPage } from 'src/pages/LoadingPage'

import { Box, Container } from './styles'

export const AuthLayout: React.FC = () => {
  const { isAuthenticated, isLoaded, user } = useAuth()
  const { setPortalId } = useAppVariables()

  if (!isLoaded) {
    return <LoadingPage />
  }

  if (isAuthenticated) {
    let navigateTo =
      localStorage.getItem(`${globalThis.storageKey}:redirectTo`) || '/'
    const noSwitchRedirect =
      sessionStorage.getItem(`${globalThis.storageKey}:no-switch`) === 'true'

    if (user.portals.length > 1 && navigateTo === '/' && !noSwitchRedirect) {
      navigateTo = '/portal-switch'
    }

    if (user.portals.length <= 1) {
      setPortalId(user.portals[0].portalId)
    }

    return <Navigate to={navigateTo} />
  }

  return (
    <Container>
      <Box>
        <Outlet />

        <a
          href="https://flowexpert.com.br"
          target="_blank"
          className="logo"
          rel="noreferrer"
        >
          Request Portal
        </a>
      </Box>
    </Container>
  )
}
