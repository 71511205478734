import styled from 'styled-components'

interface IContainerProps {
  mainColor: string
  accentColor: string
}

export const Container = styled.button<IContainerProps>`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding: 1.6rem;
  gap: 1.6rem;
  border: none;
  background-color: var(--white);
  box-shadow: var(--shadow-card);
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
  max-width: 25rem;

  .card-texts {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    width: 4rem;
    height: 4rem;
    image-rendering: pixelated;
    image-rendering: -webkit-optimize-contrast;
    object-fit: scale-down;
  }

  h4 {
    color: var(--headline);
    transition: all 0.2s;
  }

  p {
    color: var(--paragraph);
    opacity: 0.6;
    transition: all 0.2s;
  }

  &:hover {
    background-color: ${props => props.mainColor};

    h4,
    p {
      color: ${props => props.accentColor};
    }
  }
`
