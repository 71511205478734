import React, { useEffect, useState } from 'react'

import { SkeletonBox } from 'src/components/ui/loading/SkeletonBox'
import { useAppVariables } from 'src/hooks/appVariables'
import { IRequest } from 'src/interfaces/IRequest'
import { api } from 'src/services/api'

import { Request } from './Request'
import { Container, TrackingList } from './styles'

export const Requests: React.FC = () => {
  const { portalId, setCurrentPage, setPageTitle } = useAppVariables()
  const [requests, setRequests] = useState<IRequest[]>([])
  const [loadingRequests, setLoadingRequests] = useState(true)

  useEffect(() => {
    setLoadingRequests(true)
    api
      .get<IRequest[]>(`/portal/${portalId}/request-tracking`)
      .then(({ data }) => {
        setRequests(data)
        setLoadingRequests(false)
      })
  }, [portalId])

  useEffect(() => {
    setCurrentPage('requests')
    setPageTitle('Minhas Solicitações')
  }, [setCurrentPage, setPageTitle])

  return (
    <Container>
      <h1>Minhas solicitações</h1>
      <TrackingList>
        {requests.map(request => (
          <Request key={request.id} request={request} />
        ))}
        {loadingRequests && (
          <>
            <div className="fake-request">
              <div className="first-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="second-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="third-row">
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
              </div>
            </div>
            <div className="fake-request">
              <div className="first-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="second-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="third-row">
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
              </div>
            </div>
            <div className="fake-request">
              <div className="first-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="second-row">
                <SkeletonBox format="text" />
                <SkeletonBox format="text" />
              </div>
              <div className="third-row">
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
                <SkeletonBox format="line" />
                <SkeletonBox format="circle" width="3.2rem" />
              </div>
            </div>
          </>
        )}
        {!loadingRequests && requests.length === 0 && (
          <div className="empty-message">
            Nenhuma solicitação encontrada para o seu e-mail.
          </div>
        )}
      </TrackingList>
    </Container>
  )
}
