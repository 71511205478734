export const theme = {
  colors: {
    brandDark: '#00453A',
    brand: '#00856F',
    brandLight: '#DCE9E2',
    brandLight02: '#F7F9F9',

    headline: '#212529',
    paragraph: '#495057',
    white: '#FFFFFF',
    black: '#000000',

    success: '#00CAA5',
    warning: '#FFB000',
    danger: '#ff0000',

    redDark: '#800100',
    redDark02: '#CE0400',
    red: '#FF0000',
    redLight: '#FFAD95',
    redLight02: '#FFF5F2',

    pinkDark: '#820035',
    pinkDark02: '#C4005C',
    pink: '#FF007D',
    pinkLight: '#FF93CB',
    pinkLight02: '#FFF1F8',

    purpleDark: '#60007D',
    purpleDark02: '#9C00C8',
    purple: '#BD00F7',
    purpleLight: '#EA74FD',
    purpleLight02: '#FCEFFF',

    indigoDark: '#3C007D',
    indigoDark02: '#7800F0',
    indigo: '#8B00FF',
    indigoLight: '#CF89FF',
    indigoLight02: '#F7F9FF',

    blueDark: '#21007D',
    blueDark02: '#4A00F0',
    blue: '#5544FF',
    blueLight: '#ADBFFF',
    blueLight02: '#F4F7FF',

    skyDark: '#0F417C',
    skyDark02: '#216DC8',
    sky: '#3087FF',
    skyLight: '#96D4FF',
    skyLight02: '#F2FAFF',

    cyanDark: '#00617C',
    cyanDark02: '#009BB2',
    cyan: '#00BED6',
    cyanLight: '#8DE7F0',
    cyanLight02: '#F1FBFD',

    greenDark: '#00816A',
    greenDark02: '#00A688',
    green: '#00CAA5',
    greenLight: '#89EBDC',
    greenLight02: '#F0FDFA',

    limeDark: '#008700',
    limeDark02: '#06AE00',
    lime: '#00F300',
    limeLight: '#AFFF9E',
    limeLight02: '#F5FFF3',

    yellowDark: '#7B5500',
    yellowDark02: '#B27D00',
    yellow: '#FFB000',
    yellowLight: '#FEE493',
    yellowLight02: '#FFFCF1',

    orangeDark: '#7F3000',
    orangeDark02: '#B74A00',
    orange: '#FF6200',
    orangeLight: '#FFC694',
    orangeLight02: '#FFF9F2',

    grayDark: '#3D3D3D',
    grayDark02: '#545D63',
    gray: '#6C7B88',
    grayLight: '#CEDFEE',
    grayLight02: '#f9fcfe',
  },

  breakpoints: {
    xs: '577px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },

  fontSizes: {
    small: '1.4rem',
    medium: '1.6rem',
    large: '1.8rem',
    h4: '2.4rem',
    h3: '4.0rem',
    h2: '4.8rem',
    h1: '5.2rem',
  },

  shadows: {
    default: '0.05rem 0.05rem 0.2rem rgba(0, 0, 0, 0.4)',
    card: '0.1rem 0.1rem 0.6rem rgba(0, 0, 0, 0.1)',
  },
}

export type CustomTheme = typeof theme
export type ThemeColors = typeof theme.colors
export type ThemeColorsKeys = keyof ThemeColors

export const mergeThemes = (
  ...themes: (Partial<CustomTheme> | undefined | null)[]
): CustomTheme => {
  const mainTheme = JSON.parse(JSON.stringify(theme))
  themes = JSON.parse(JSON.stringify(themes))

  const colors = Object.assign(
    mainTheme.colors,
    ...themes.map(theme => theme?.colors || {})
  )
  const breakpoints = Object.assign(
    mainTheme.breakpoints,
    ...themes.map(theme => theme?.breakpoints || {})
  )
  const fontSizes = Object.assign(
    mainTheme.fontSizes,
    ...themes.map(theme => theme?.fontSizes || {})
  )
  const shadows = Object.assign(
    mainTheme.shadows,
    ...themes.map(theme => theme?.shadows || {})
  )

  return {
    colors,
    breakpoints,
    fontSizes,
    shadows,
  }
}

export const getTheme = (): CustomTheme => {
  const savedTheme = JSON.parse(
    localStorage.getItem(`${globalThis.storageKey}:theme`) || '{}'
  )

  return mergeThemes(savedTheme)
}

export const setTheme = (newTheme: Partial<CustomTheme> | undefined) => {
  if (newTheme) {
    localStorage.setItem(
      `${globalThis.storageKey}:theme`,
      JSON.stringify(newTheme)
    )
  } else {
    localStorage.removeItem(`${globalThis.storageKey}:theme`)
  }
}
