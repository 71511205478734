import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppVariables } from 'src/hooks/appVariables'
import { IPortal } from 'src/interfaces/IPortal'
import { api } from 'src/services/api'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import { Container, PortalList } from './styles'

export const PortalSwitch: React.FC = () => {
  const navigate = useNavigate()
  const {
    setCurrentPage,
    setPageTitle,
    portals: userPortals,
    setPortalId,
  } = useAppVariables()
  const [portals, setPortals] = useState<IPortal[]>([])

  useEffect(() => {
    setCurrentPage('portal-switch')
    setPageTitle('Trocar de Portal')
  }, [setCurrentPage, setPageTitle])

  function handleChoosePortal(portalId: string) {
    setPortalId(portalId)
    navigate('/')
  }

  useEffect(() => {
    async function getPortals() {
      const { data } = await api.get('/portal/portals')

      setPortals(data)
    }

    setPortals(userPortals)
    getPortals()
  }, [userPortals])

  return (
    <Container>
      <h1>Trocar de Portal</h1>

      <h2>Você é membro de {portals?.length || 0} portais.</h2>
      <p>Selecione o portal que você quer acessar.</p>

      <PortalList itemsCount={portals.length}>
        {portals.map(portal => (
          <button
            type="button"
            key={portal.portalId}
            onClick={() => handleChoosePortal(portal.portalId)}
          >
            <img src={formatImgUrl(portal?.logoUrl)} alt={portal.name} />
            <h3>{portal.name}</h3>
          </button>
        ))}
      </PortalList>
    </Container>
  )
}
