import styled, { css, keyframes } from 'styled-components'

const skeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 60%);
  }

  100% {
    background-color: hsl(200, 20%, 80%);
  }
`

interface IContainerProps {
  format: 'circle' | 'box' | 'text' | 'line'
  width?: string
  height?: string
}

export const Container = styled.div<IContainerProps>`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  opacity: 0.7;

  ${props =>
    props.format === 'circle' &&
    css`
      width: ${props.width || '5.6rem'};
      height: ${props.height || props.width || '5.6rem'};
      border-radius: 50%;
    `}

  ${props =>
    props.format === 'box' &&
    css`
      width: ${props.width || '100%'};
      height: ${props.height || '5.6rem'};
      border-radius: 0.8rem;
    `}

  ${props =>
    props.format === 'text' &&
    css`
      width: ${props.width || '100%'};
      height: ${props.height || '2.4rem'};
      border-radius: 0.4rem;
    `}

    ${props =>
    props.format === 'line' &&
    css`
      width: ${props.width || '100%'};
      height: ${props.height || '0.4rem'};
    `}
`
