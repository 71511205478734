import React, { useMemo } from 'react'

import { IRequest } from 'src/interfaces/IRequest'

import { Body, Container, Header, Roadmap } from './styles'

interface IPhase {
  id: string
  name: string
  index: number
  done: boolean
  error: boolean
  current: boolean
}

interface IRequestProps {
  request: IRequest
}

export const Request: React.FC<IRequestProps> = ({ request }) => {
  const phases: IPhase[] = useMemo(() => {
    if (!request.pipe.phases) return []

    const currentPhaseIndex = request.pipe.phases.findIndex(
      phase => phase.id === request.current_phase.id
    )

    const undonePhases = request.pipe.phases
      .filter(phase => !phase.done)
      .map((phase, index) => {
        return {
          id: phase.id,
          name: phase.name,
          index: phase.index,
          done: false,
          error: false,
          current: index <= currentPhaseIndex,
        }
      })

    const donePhases = request.pipe.phases
      .filter(phase => phase.done)
      .map((phase, index) => {
        return {
          id: phase.id,
          name: phase.name,
          index: phase.index,
          done: index === 0,
          error: index !== 0,
          current: index + undonePhases.length <= currentPhaseIndex,
        }
      })

    const visiblePhases = [...undonePhases]

    if (request.current_phase.done) {
      const donePhase = donePhases.find(
        phase => phase.id === request.current_phase.id
      )
      if (donePhase) {
        visiblePhases.push(donePhase)
      } else {
        visiblePhases.push(donePhases[0])
      }
    } else {
      visiblePhases.push(donePhases[0])
    }

    return visiblePhases
  }, [request])

  const date = useMemo(() => {
    if (!request.createdAt) return ''
    const date = new Date(request.createdAt)
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`
  }, [request])

  return (
    <Container>
      <Header>
        <h3>{request.service.name}</h3>

        <span>#{request.id}</span>
      </Header>

      <Body>
        <div className="status">
          <p>
            Status: <strong>{request.current_phase.name}</strong>
          </p>

          <p>{date}</p>
        </div>

        <Roadmap>
          {phases.map(phase => (
            <div
              key={phase.id}
              className={`checkpoint${phase.current ? ' active' : ''}`}
              title={phase.name}
            >
              <span className="line"></span>
              <span className="check">
                {phase.done && <p>✔</p>}
                {phase.error && <p>✘</p>}
              </span>
            </div>
          ))}
        </Roadmap>
      </Body>
    </Container>
  )
}
