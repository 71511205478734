import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { AxiosError } from 'axios'
import decode from 'jwt-decode'
import { IUser } from 'src/interfaces/IUser'
import { api } from 'src/services/api'

interface ILoginData {
  email: string
  password: string
}
interface IADData {
  usingAD: boolean
  companyCode?: string
  portalCode?: string
}

interface IPasswordUpdate {
  oldPassword: string
  password: string
  confirmPassword: string
}

interface IAuthContextData {
  user: IUser
  isAuthenticated: boolean
  isLoaded: boolean
  login: (data: ILoginData, ADData?: IADData) => Promise<void>
  logout: () => void

  updateUser: (portalId: string, user: Partial<IUser>) => Promise<void>
  updateUserPassword: (portalId: string, data: IPasswordUpdate) => Promise<void>
  updateUserAvatar: (portalId: string, avatar: File) => Promise<void>
}

const AuthContext = createContext({} as IAuthContextData)

const storageKey = globalThis.storageKey

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser>(() => {
    const user = localStorage.getItem(`${storageKey}:user`)
    if (user) {
      return JSON.parse(user)
    }
    return {} as IUser
  })
  const [token, setToken] = useState<string>(() => {
    const token = localStorage.getItem(`${storageKey}:token`)
    if (token) {
      return token
    }
    return ''
  })
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const logout = useCallback(() => {
    Object.keys(localStorage).forEach((key: string) => {
      if (key.startsWith(`${storageKey}:`)) {
        localStorage.removeItem(key)
      }
    })

    api.defaults.headers.common.authorization = false
    setUser({} as IUser)
    setToken('')
    setIsLoaded(true)
    setIsAuthenticated(false)
  }, [])

  const login = useCallback(
    async (
      { email, password }: ILoginData,
      ADData?: IADData
    ): Promise<void> => {
      try {
        let userData = {} as Partial<{ user: IUser; token: string }>
        if (ADData?.usingAD) {
          const { data } = await api.post('/auth/active-directory/login', {
            username: email,
            password,
            companyCode: ADData.companyCode,
            portalCode: ADData.portalCode,
          })

          userData = data
        } else {
          const { data } = await api.post('/auth/portal/login', {
            email,
            password,
          })

          userData = data
        }

        if (!userData?.user || !userData?.token) {
          throw new Error(
            'Ocorreu algum problema, por favor, tente novamente mais tarde.'
          )
        }

        api.defaults.headers.common.authorization = `Bearer ${userData.token}`
        localStorage.setItem(
          `${storageKey}:user`,
          JSON.stringify(userData.user)
        )
        localStorage.setItem(`${storageKey}:token`, userData.token)

        setUser(userData.user)
        setToken(userData.token)
        setIsAuthenticated(true)
        setIsLoaded(true)
      } catch (error) {
        logout()

        if (
          error instanceof AxiosError &&
          (error.response?.status === 401 || error.response?.status === 400)
        ) {
          throw new Error('E-mail ou senha inválidos.')
        }

        throw new Error(
          'Ocorreu algum problema, por favor, tente novamente mais tarde.'
        )
      }
    },
    [logout]
  )

  const updateUser = useCallback(
    async (portalId: string, input: Partial<IUser>) => {
      const { data } = await api.patch(`/portal/${portalId}/account`, input)

      setUser(state => {
        return {
          ...state,
          ...data,
        }
      })
    },
    []
  )

  const updateUserAvatar = useCallback(
    async (portalId: string, avatar: File) => {
      const formData = new FormData()
      formData.append('avatar', avatar)

      const { data } = await api.post(
        `/portal/${portalId}/account/avatar-upload`,
        formData
      )

      data.avatarUrl = `${data.avatarUrl}?v=${new Date().getTime()}`

      setUser(state => {
        return {
          ...state,
          avatarUrl: data.avatarUrl,
        }
      })
    },
    []
  )

  const updateUserPassword = useCallback(
    async (
      portalId: string,
      { password, oldPassword, confirmPassword }: IPasswordUpdate
    ) => {
      await api.put(`/portal/${portalId}/account`, {
        password,
        oldPassword,
        confirmPassword,
      })
    },
    []
  )

  useEffect(() => {
    function testToken() {
      const localToken = localStorage.getItem(`${storageKey}:token`)

      let tryToken = token
      if (localToken && localToken !== token) {
        tryToken = localToken
      }

      if (tryToken) {
        try {
          const { exp } = decode(tryToken) as { exp: number }
          if (Date.now() >= exp * 1000) {
            return logout()
          }
        } catch {
          return logout()
        }
      } else {
        return logout()
      }

      if (localToken && localToken !== token) {
        setToken(localToken)
      }
    }

    if (token) {
      api.defaults.headers.common.authorization = `Bearer ${token}`
      setIsAuthenticated(true)
      setIsLoaded(true)
    } else {
      api.defaults.headers.common.authorization = false
      const storedUser = localStorage.getItem(`${storageKey}:user`)
      const storedToken = localStorage.getItem(`${storageKey}:token`)
      if (storedUser && storedToken) {
        api.defaults.headers.common.authorization = `Bearer ${storedToken}`
        setUser(JSON.parse(storedUser) as IUser)
        setToken(storedToken)
        setIsAuthenticated(true)
        setIsLoaded(true)
      } else {
        logout()
      }
    }

    const interval = setInterval(testToken, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [logout, token])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        isLoaded,

        updateUser,
        updateUserAvatar,
        updateUserPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContextData => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
