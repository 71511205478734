import { AppVariablesContextProvider } from './appVariables'
import { AuthContextProvider } from './auth'

interface IHooksProps {
  children: React.ReactNode
}

export const Hooks: React.FC<IHooksProps> = ({ children }) => {
  return (
    <AuthContextProvider>
      <AppVariablesContextProvider>{children}</AppVariablesContextProvider>
    </AuthContextProvider>
  )
}
