import { Container } from './styles'

interface ISkeletonBoxProps {
  format: 'circle' | 'box' | 'text' | 'line'
  width?: string
  height?: string
}

export const SkeletonBox: React.FC<ISkeletonBoxProps> = ({
  format,
  width,
  height,
}) => {
  return <Container format={format} width={width} height={height}></Container>
}
