/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { useAppVariables } from 'src/hooks/appVariables'
import { formatImgUrl } from 'src/utils/formatImgUrl'

import ProfileOptions from './ProfileOptions'
import { Container, ExpandMenuButton, Menu } from './styles'

export const Header: React.FC = () => {
  const { portal, currentPage } = useAppVariables()
  const [menuIsExpanded, setMenuIsExpanded] = useState(false)

  const menuItems = useMemo(() => {
    const pages = [
      {
        code: 'catalog',
        label: 'Catálogo de Serviços',
        path: '/',
        active: currentPage === 'catalog',
      },
      {
        code: 'requests',
        label: 'Minhas Solicitações',
        path: '/requests',
        active: currentPage === 'requests',
      },
    ]

    if (portal.publicFormUrl) {
      pages.push({
        code: 'help',
        label: 'Ajuda',
        path: '/help',
        active: currentPage === 'help',
      })
    }

    return pages
  }, [currentPage, portal.publicFormUrl])

  return (
    <Container>
      <ExpandMenuButton onClick={() => setMenuIsExpanded(!menuIsExpanded)}>
        {menuIsExpanded ? <FiX /> : <FiMenu />}
      </ExpandMenuButton>

      <Link className="logo" to="/">
        {(portal?.logoUrl && (
          <img src={formatImgUrl(portal.logoUrl)} alt={portal.name} />
        )) ||
          portal?.name}
      </Link>

      <Menu className={menuIsExpanded ? 'active' : ''}>
        <ul>
          {menuItems.map(item => (
            <li
              key={item.code}
              className={item.active ? 'active' : ''}
              onClick={() => setMenuIsExpanded(false)}
            >
              <Link to={item.path}>{item.label}</Link>
            </li>
          ))}
        </ul>

        <ProfileOptions />
      </Menu>
    </Container>
  )
}
