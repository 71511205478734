import Axios from 'axios'

const baseURL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3333/api/v1'
const storageKey = globalThis.storageKey
const token = localStorage.getItem(`${storageKey}:token`)

export const api = Axios.create({
  baseURL,
})

api.defaults.headers.common.authorization = `Bearer ${token}`
