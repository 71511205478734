import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    font-size: 3.2rem;
    color: var(--headline);
    text-align: center;
    margin-top: 3.2rem;
    font-weight: 500;
  }

  .empty-message {
    margin: 6.4rem;
  }

  .fake-request {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: var(--shadow-card);
    width: 90vw;
    padding: 1.6rem;
    border-radius: 0.8rem;

    gap: 1.6rem;

    .first-row {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 60%;
    }
    .second-row {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 70%;
    }
    .third-row {
      display: grid;
      grid-template-columns: 3.2rem 1fr 3.2rem 1fr 3.2rem 1fr 3.2rem;
    }
  }
`

export const TrackingList = styled.ul`
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  div {
    margin: auto 0;
  }
`
