import { useEffect } from 'react'

import { CustomTheme, setTheme, getTheme } from 'src/styles/theme'

import { useAppVariables } from './appVariables'

interface IThemeController {
  setCurrentTheme: (data: CustomTheme) => void
}

export const ThemeController: React.FC<IThemeController> = ({
  setCurrentTheme,
}) => {
  const { portal } = useAppVariables()

  useEffect(() => {
    if (portal?.styleScheme) {
      setTheme(portal?.styleScheme)
      setCurrentTheme(getTheme())
    } else if (!portal) {
      setCurrentTheme(getTheme())
    } else {
      setTheme(undefined)
      setCurrentTheme(getTheme())
    }
  }, [portal, setCurrentTheme])

  return <></>
}
