import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { i18n } from 'src/assets/i18n'
import { Button } from 'src/components/inputs/Button'
import { Input } from 'src/components/inputs/Input'
import { Link } from 'src/components/inputs/Link'
import { useAppVariables } from 'src/hooks/appVariables'
import { useAuth } from 'src/hooks/auth'
import { api } from 'src/services/api'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Container } from './styles'

interface IFormData {
  email: string
  password: string
}

interface IParams {
  companyCode: string
  portalCode: string
}

const schema = yup.object({
  email: yup
    .string()
    .email('Precisa ser um e-mail válido.')
    .required('O e-mail é obrigatório.'),
  password: yup
    .string()
    .min(8, 'A senha deve ter no mínimo 8 caracteres.')
    .required('A senha é obrigatória.'),
})

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [usingAD, setUsingAD] = useState(false)
  const { login } = useAuth()
  const { setPageTitle } = useAppVariables()
  const { companyCode, portalCode } = useParams<Partial<IParams>>()

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema),
  })

  async function onSubmit(input: IFormData) {
    setLoading(true)
    try {
      await login(input, {
        usingAD,
        companyCode,
        portalCode,
      })
    } catch (error) {
      let message = 'Ocorreu um erro ao tentar fazer login.'
      if (error instanceof Error) {
        message = error.message
      }

      methods.setError('password', {
        type: 'validate',
        message,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPageTitle('Login | Request Portal - By FlowExpert')
  }, [setPageTitle])

  useEffect(() => {
    async function getCompany() {
      try {
        const { data } = await api.get(`/portal/company/${companyCode}`)

        setUsingAD(data.usingAD)
        setLoading(false)
      } catch {
        setUsingAD(false)
        setLoading(false)
      }
    }

    if (companyCode) {
      setLoading(true)
      getCompany()
    }
  }, [companyCode])

  return (
    <Container>
      <h1>{i18n(globalThis.lang, 'message/app-name')}</h1>
      <h2>{i18n(globalThis.lang, 'message/enter')}</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            label="E-mail"
            name="email"
            type="email"
            placeholder="Seu E-mail"
            hoverToShowError={false}
            required
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder="Sua senha"
            hoverToShowError={false}
            required
          />
          <Button type="submit" loading={loading}>
            Entrar
          </Button>
        </form>
      </FormProvider>
      <Link to="/forgot-password">Esqueci a senha</Link>
    </Container>
  )
}
