import styled, { css } from 'styled-components'

interface IContainerProps {
  color: string
  format: 'small' | 'medium' | 'large'
  variant: 'primary' | 'secondary'
}

export const Container = styled.button<IContainerProps>`
  width: 100%;
  height: 5rem;
  border-radius: 2.5rem;
  margin-bottom: 0.8rem;
  border: none;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 8.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  ${({ variant, color }) =>
    variant === 'primary' &&
    css`
      background-color: var(--${color || 'brand'});
      color: var(--white);
    `}

  ${({ variant, color }) =>
    variant === 'secondary' &&
    css`
      background-color: transparent;
      border: 0.2rem solid var(--${color || 'brand'});
      color: var(--${color || 'brand'});
    `}

  ${props =>
    props.format === 'small' &&
    css`
      font-size: 1.2rem;
      height: 4rem;
      padding: 0 1.6rem;
    `}

  ${props =>
    props.format === 'large' &&
    css`
      font-size: 1.6rem;
      height: 6rem;
      padding: 0 3.2rem;
    `}

  svg {
    font-size: 1.8rem;
  }

  &:hover {
    background-color: var(--${({ color }) => color || 'brand'}-dark);
    letter-spacing: 0.2rem;
    opacity: 1 !important;
    color: var(--white);
  }

  &:disabled {
    filter: grayscale(100%);
  }
`
