import styled from 'styled-components'

export const Container = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url('/assets/images/backgrounds/arlington-research-kN_kViDchA0-unsplash.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
`

export const Box = styled.aside`
  background-color: var(--white);
  padding: 5.6rem 3.2rem;
  max-width: 40rem;
  width: 100%;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--headline);
    font-size: var(--h3);
  }
  h2 {
    margin-bottom: 4rem;
  }
`
