import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  height: 100vh;
  grid-template-rows: 6rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'header' 'main' 'footer';

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-areas: 'header' 'main' 'footer';
  }
`

export const Wrapper = styled.div`
  grid-area: main;
  background-color: var(--brand-light-02);

  display: flex;
  flex-direction: column;
`
