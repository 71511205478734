export const formatImgUrl = (url: string | undefined | null): string => {
  if (!url) return ''

  if (url && url.includes('?color=#')) {
    url = url.replace('?color=#', '?color=%23')
  }

  if (url.startsWith('http')) return url
  if (url.startsWith('/')) return `${globalThis.apiUrl}${url}`

  return `${globalThis.apiUrl}/${url}`
}
