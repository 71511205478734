import './assets/global'

import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from 'styled-components'

import { Hooks } from './hooks'
import { ThemeController } from './hooks/themeController'
import { Router } from './router'
import GlobalStyle from './styles/global'
import { getTheme } from './styles/theme'

function App() {
  const [theme, setTheme] = useState(getTheme)

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Hooks>
          <ThemeController setCurrentTheme={setTheme} />
          <Router />
          <GlobalStyle />
        </Hooks>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
