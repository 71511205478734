import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  .empty-message {
    text-align: center;
    margin: 64px;
  }
`
