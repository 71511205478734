import styled from 'styled-components'

export const Container = styled.div`
  grid-area: footer;
  padding: 3.2rem 1.6rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.8rem;

  p {
    color: var(--gray);
    opacity: 0.6;
    text-align: center;
  }
`
