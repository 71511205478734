import { Routes, Route } from 'react-router-dom'

import { Category } from 'src/pages/Category'
import { Help } from 'src/pages/Help'
import { Login } from 'src/pages/Login'
import { Logout } from 'src/pages/Logout'
import { PortalSwitch } from 'src/pages/PortalSwitch'
import { Profile } from 'src/pages/Profile'
import { Requests } from 'src/pages/Requests'
import { Service } from 'src/pages/Service'
import { AppLayout } from 'src/styles/layout/AppLayout'
import { AuthLayout } from 'src/styles/layout/AuthLayout'

import { Home } from '../pages/Home'

export function Router() {
  return (
    <Routes>
      <Route path="/login" element={<AuthLayout />}>
        <Route index element={<Login />} />
        <Route path=":companyCode" element={<Login />} />
        <Route path=":companyCode/:portalCode" element={<Login />} />
      </Route>

      <Route path="/logout" element={<Logout />} />

      <Route path="/" element={<AppLayout />}>
        <Route index element={<Home />} />

        <Route path="/category/:slug" element={<Category />} />

        <Route path="/service/:slug" element={<Service />} />

        <Route path="/requests" element={<Requests />} />

        <Route path="/help" element={<Help />} />

        <Route path="/portal-switch" element={<PortalSwitch />} />

        <Route path="/profile" element={<Profile />} />

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  )
}
