import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

import { Container } from './styles'

interface ILoadingProps {
  size?: string | number
  color?: string
}

export const CircleLoading: React.FC<ILoadingProps> = ({
  size = '1.6rem',
  color = 'var(--brand-light)',
}) => {
  return (
    <Container>
      <AiOutlineLoading3Quarters size={size} color={color} />
    </Container>
  )
}
