import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--paragraph);

  &.inside-banner {
    margin-top: -12.8rem;
  }

  input {
    width: 100%;
    max-width: 120rem;
    padding: 1.6rem 3.2rem;
    border-radius: 0.8rem;
    border: 0.2rem solid var(--brand);
    background-color: var(--white);

    &::placeholder {
      color: var(--paragraph);
      opacity: 0.4;
    }
  }

  .search-results {
    max-width: 120rem;
    width: calc(100% - 6.4rem);
    border-radius: 0.8rem;
    border: 0.2rem solid var(--brand);
    background-color: var(--brand-light-02);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 0.8rem);
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    transition: opacity 0.2s ease-in-out;
    max-height: 40rem;
    overflow-y: auto;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      width: 100%;

      a {
        width: 100%;
        display: block;
        padding: 0.8rem 1.6rem;
        color: var(--paragraph);

        &:hover {
          background-color: var(--brand-light);
        }

        .type {
          opacity: 0.6;
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
        }

        p {
          text-transform: capitalize;
          color: var(--paragraph);
        }

        > span {
          display: block;
        }

        &.no-link {
          background-color: var(--brand-light-02);

          p {
            text-transform: none;
          }
        }
      }
    }
  }
`

export const Highlight = styled.span`
  background-color: var(--brand-light);
  color: var(--headline);
`
