import styled from 'styled-components'

import { darken, lighten, transparentize } from 'polished'
import { CustomTheme } from 'src/styles/theme'
import { formatImgUrl } from 'src/utils/formatImgUrl'

const getImage = ({
  theme,
  imageUrl,
  mainColor,
  shadowColor = '#000000',
}: {
  theme: CustomTheme
  imageUrl?: string
  mainColor?: string
  shadowColor?: string
}) => {
  let image

  if (imageUrl && imageUrl.includes('.svg')) {
    image = formatImgUrl(
      `${imageUrl}?color=${lighten(0.1, mainColor || theme.colors.brand)}`
    )
  } else if (imageUrl) {
    image = formatImgUrl(imageUrl)
  } else {
    image = formatImgUrl(
      `/defaults/default-banner.svg?color=${lighten(
        0.1,
        mainColor || theme.colors.brand
      )}`
    )
  }

  return `linear-gradient(${transparentize(0.4, shadowColor)}, ${transparentize(
    0.4,
    shadowColor
  )}), url(${image})`
}

interface IContainerProps {
  imageUrl?: string
  mainColor?: string
  currentBanner: number
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  min-height: 26.4rem;

  .banner-container {
    display: flex;
    overflow: hidden;
    max-width: 100vw;
    position: relative;
  }

  .banner {
    display: flex;
    flex-direction: column;
    min-height: 26.4rem;
    height: 100%;
    width: 100vw;
    min-width: 100vw;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-${({ currentBanner }) => currentBanner * 100}%);
    background-position: center;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 120rem;
      width: 100%;
      height: 100%;
      flex: 1;
      padding: 0;
      margin: 0 auto;

      &.search-inside-banner {
        padding-bottom: 8.8rem;
      }

      > h1,
      > h2 {
        font-size: 5.6rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        line-height: 5.6rem;
        color: var(--white);
      }

      > p {
        color: var(--white);
        opacity: 0.8;
        margin-top: 0.8rem;
      }
    }
    .search-bar {
      margin-top: 3.2rem;
    }
  }

  .default-banner {
    display: flex;
    flex-direction: column;
    background-color: var(--brand);
    background-image: ${getImage};
    color: var(--white);
    padding-bottom: 3.2rem;

    .search-bar {
      margin-top: 0;
    }

    h1,
    h2 {
      text-align: center;
    }
  }
`

interface IBannerItemProps {
  imageUrl?: string
  mainColor?: string
  accentColor?: string
  shadowColor?: string
  fillType?: 'coverable' | 'repeatable'
}

export const BannerItem = styled.div<IBannerItemProps>`
  background-color: ${({ mainColor, theme }) =>
    mainColor || theme.colors.brand};
  background-image: ${getImage};
  color: ${({ accentColor, theme }) => accentColor || theme.colors.white};
  background-position: center;
  background-size: ${({ fillType }) =>
    fillType === 'coverable' ? 'cover' : 'contain'};
  background-repeat: ${({ fillType }) =>
    fillType === 'coverable' ? 'no-repeat' : 'repeat'};
  height: fit-content !important;
  padding: 3.2rem;
`

interface IBannerButtonProps {
  backgroundColor?: string
  textColor?: string
}

export const BannerButton = styled.a<IBannerButtonProps>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.brand};
  color: ${({ theme, textColor }) => textColor || theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 1.6rem 3.2rem;
  border-radius: 0.8rem;
  margin-top: 1.6rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme, backgroundColor }) =>
      darken(0.05, backgroundColor || theme.colors.brand)};
  }
`

export const PrevButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4rem;

  background-color: rgba(0, 0, 0, 0.5);
  border: none;

  svg {
    font-size: 2.4rem;
    color: var(--white);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4rem;

  background-color: rgba(0, 0, 0, 0.5);
  border: none;

  svg {
    font-size: 2.4rem;
    color: var(--white);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`

export const SlideBullets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1.6rem;
  left: 50%;
  transform: translateX(-50%);

  .bullet {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: var(--white);
    margin: 0 0.4rem;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;

    &.active {
      opacity: 1;
      width: 1rem;
      height: 1rem;
    }
  }
`
