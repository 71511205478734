import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppVariables } from 'src/hooks/appVariables'
import { IService } from 'src/interfaces/IService'
import { api } from 'src/services/api'

import { Container } from './styles'

export const Service: React.FC = () => {
  const [service, setService] = React.useState<IService>()
  const { portalId, setCurrentPage, setPageTitle, portal } = useAppVariables()
  const { slug } = useParams()

  useEffect(() => {
    api
      .get<IService>(`/portal/${portalId}/services/${slug}`)
      .then(({ data }) => {
        setService(data)
      })
  }, [portalId, slug])

  useEffect(() => {
    setCurrentPage('catalog')
    if (service?.name && portal?.name) {
      setPageTitle(`${service.name} - ${portal.name}`)
    } else if (service?.name && !portal?.name) {
      setPageTitle(service.name)
    } else if (!service?.name && portal?.name) {
      setPageTitle(portal.name)
    } else {
      setPageTitle('Request Portal')
    }
  }, [portal.name, service, setCurrentPage, setPageTitle])

  return (
    <Container>
      <h1>{service?.name}</h1>

      {service?.publicFormUrl && (
        <iframe
          src={`${service.publicFormUrl}?embedded=true`}
          title={service.name}
          frameBorder="0"
        ></iframe>
      )}
    </Container>
  )
}
