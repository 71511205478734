import { createContext, useContext, useEffect, useState } from 'react'

import { IPortal } from 'src/interfaces/IPortal'
import { api } from 'src/services/api'

import { useAuth } from './auth'

interface IAppVariablesContextData {
  portals: IPortal[]
  title: string
  setTitle: (title: string) => void
  pageTitle: string
  setPageTitle: (pageTitle: string) => void
  portalId: string
  setPortalId: (portalId: string) => void
  portal: IPortal
  setPortal: (portal: IPortal) => void
  currentPage: string
  setCurrentPage: (currentPage: string) => void
}

const AppVariablesContext = createContext({} as IAppVariablesContextData)

const storageKey = globalThis.storageKey

export const AppVariablesContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [title, setTitle] = useState('')
  const { user } = useAuth()
  const [pageTitle, setPageTitle] = useState('Portal FlowExpert')
  const [currentPage, setCurrentPage] = useState('catalog')
  const [portalId, setPortalId] = useState((): string => {
    const portalId = localStorage.getItem(`${storageKey}:portalId`)
    if (portalId) {
      return portalId
    }
    return ''
  })
  const [portals, setPortals] = useState<IPortal[]>(() => {
    const portals = localStorage.getItem(`${storageKey}:portals`)
    if (portals) {
      return JSON.parse(portals)
    }
    if (user?.portals) {
      return user.portals
    }
    return []
  })
  const [portal, setPortal] = useState<IPortal>(() => {
    const portalString = localStorage.getItem(`${storageKey}:portal`)
    if (portalString) {
      return JSON.parse(portalString)
    }
    if (user?.portals?.[0]) {
      return user?.portals?.[0]
    }
    return {} as IPortal
  })

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle
    }
  }, [pageTitle])

  useEffect(() => {
    async function getPortals() {
      const { data } = await api.get<IPortal[]>('/portal/portals')

      setPortals(data)
    }

    getPortals()
  }, [])

  useEffect(() => {
    const currentPortalId = portalId

    if (!currentPortalId) {
      if (portals[0]?.portalId) {
        setPortalId(portals[0]?.portalId)
        return
      }
    }

    async function getPortal() {
      const { data } = await api.get<IPortal>(
        `/portal/portals/${currentPortalId}`
      )
      setPortal(data)
    }

    const currentPortal = portals.find(item => item.portalId === portalId)
    if (currentPortal) {
      setPortal(currentPortal)
    }

    getPortal()
  }, [portalId, portals])

  useEffect(() => {
    if (portalId) {
      localStorage.setItem(`${storageKey}:portalId`, portalId)
    }
    if (portals && portals.length) {
      localStorage.setItem(`${storageKey}:portals`, JSON.stringify(portals))
    }
    if (portal) {
      localStorage.setItem(`${storageKey}:portal`, JSON.stringify(portal))
    }
  }, [portal, portalId, portals])

  return (
    <AppVariablesContext.Provider
      value={{
        portals,
        title,
        setTitle,
        pageTitle,
        setPageTitle,
        currentPage,
        setCurrentPage,
        portalId,
        setPortalId,
        portal,
        setPortal,
      }}
    >
      {children}
    </AppVariablesContext.Provider>
  )
}

export const useAppVariables = () => {
  const context = useContext(AppVariablesContext)

  if (!context) {
    throw new Error(
      'useAppVariables must be used within a AppVariablesContextProvider'
    )
  }

  return context
}
