import { ReactNode, useEffect, useState } from 'react'

import { useAppVariables } from 'src/hooks/appVariables'
import { ICategory } from 'src/interfaces/ICategory'
import { IService } from 'src/interfaces/IService'
import { api } from 'src/services/api'

import { Container, Highlight } from './styles'

interface ISearchItem {
  id: string
  type: 'category' | 'service'
  name: string
  text: ReactNode
  link: string
}

interface ISearchBannerProps {
  isInsideBanner?: boolean
}

export const SearchBar: React.FC<ISearchBannerProps> = ({
  isInsideBanner = false,
}) => {
  const { portalId } = useAppVariables()
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState<ISearchItem[]>([])
  const [loadingResults, setLoadingResults] = useState(false)

  useEffect(() => {
    async function getCategoriesAndServices() {
      setLoadingResults(true)
      const categoriesPromise = api.get<ICategory[]>(
        `/portal/${portalId}/categories?search=${searchTerm}`
      )
      const servicesPromise = api.get<IService[]>(
        `/portal/${portalId}/services?search=${searchTerm}`
      )

      const [{ data: categories }, { data: services }] = await Promise.all([
        categoriesPromise,
        servicesPromise,
      ])

      const newResults: ISearchItem[] = [
        ...categories.map(
          (category): ISearchItem => ({
            id: category.categoryId,
            type: 'category',
            name: category.name,
            link: `/category/${category.slug}`,
            text: category.name
              .toLowerCase()
              .split(searchTerm.toLocaleLowerCase())
              .map((part, index, arr) => (
                <>
                  {part}
                  {index < arr.length - 1 && (
                    <Highlight>{searchTerm}</Highlight>
                  )}
                </>
              )),
          })
        ),
        ...services.map(
          (service): ISearchItem => ({
            id: service.serviceId,
            type: 'service',
            name: service.name,
            link: `/service/${service.slug}`,
            text: service.name
              .toLowerCase()
              .split(searchTerm.toLocaleLowerCase())
              .map((part, index, arr) => (
                <>
                  {part}
                  {index < arr.length - 1 && (
                    <Highlight>{searchTerm}</Highlight>
                  )}
                </>
              )),
          })
        ),
      ]

      newResults.sort((a, b) => b.name.localeCompare(a.name))

      setResults(newResults)
      setLoadingResults(false)
    }

    if (searchTerm.length > 0) {
      getCategoriesAndServices()
    } else {
      setResults([])
    }
  }, [portalId, searchTerm])

  return (
    <Container
      className={`search-bar${isInsideBanner ? ' inside-banner' : ''}`}
    >
      <input
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        type="text"
        placeholder="Busque por serviços e categoria"
      />

      {searchTerm.length >= 2 && results.length >= 1 && (
        <ul className="search-results">
          {results.map(result => (
            <li key={result.id}>
              <a href={result.link}>
                {result.type === 'category' && (
                  <span className="type">Categoria</span>
                )}
                {result.type === 'service' && (
                  <span className="type">Serviço</span>
                )}
                <p>{result.text}</p>
              </a>
            </li>
          ))}
        </ul>
      )}
      {searchTerm.length > 0 && results.length === 0 && (
        <ul className="search-results">
          <li>
            <a className="no-link">
              <p>
                {loadingResults
                  ? 'Carregando...'
                  : 'Nenhum resultado encontrado para o termo pesquisado.'}
              </p>
            </a>
          </li>
        </ul>
      )}
    </Container>
  )
}
