import React, { useEffect } from 'react'

import { useAppVariables } from 'src/hooks/appVariables'

import { Container } from './styles'

export const Help: React.FC = () => {
  const { portal, setCurrentPage, setPageTitle } = useAppVariables()

  useEffect(() => {
    setCurrentPage('help')

    if (portal?.name) {
      setPageTitle(`Solicitar Ajuda - ${portal.name}`)
    } else {
      setPageTitle(`Solicitar Ajuda`)
    }
  }, [portal, setCurrentPage, setPageTitle])

  return (
    <Container>
      <h1>Solicitar Ajuda</h1>

      {portal?.publicFormUrl && (
        <iframe
          src={`${portal.publicFormUrl}?embedded=true`}
          title={portal.name}
          frameBorder="0"
        ></iframe>
      )}
    </Container>
  )
}
