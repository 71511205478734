/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { useAppVariables } from 'src/hooks/appVariables'

import { SearchBar } from '../SearchBar'
import {
  BannerButton,
  BannerItem,
  Container,
  NextButton,
  PrevButton,
  SlideBullets,
} from './styles'

export interface IBannerData {
  id: string
  title: string
  text?: string
  imageUrl: string
  fillType?: 'coverable' | 'repeatable'
  position?: string
  mainColor?: string
  shadowColor?: string
  button?: {
    url?: string
    text?: string
    color?: string
    textColor?: string
  }
}

interface IBannerProps {
  banners?: IBannerData[]
  bannerTitle?: string
  isSearchInsideBanner?: boolean
  isSearchInsideDefaultBanner?: boolean
}

export const Banner: React.FC<IBannerProps> = ({
  banners = [],
  bannerTitle,
  isSearchInsideBanner = false,
  isSearchInsideDefaultBanner = false,
}) => {
  const interval = useRef<NodeJS.Timer>()
  const { portal } = useAppVariables()
  const [currentBanner, setCurrentBanner] = React.useState(0)

  const handleNextBanner = useCallback(() => {
    if (currentBanner < banners.length - 1) {
      setCurrentBanner(currentBanner + 1)
    } else {
      setCurrentBanner(0)
    }
  }, [currentBanner, banners.length])

  const handlePrevBanner = useCallback(() => {
    if (currentBanner > 0) {
      setCurrentBanner(currentBanner - 1)
    } else {
      setCurrentBanner(banners.length - 1)
    }
  }, [banners.length, currentBanner])

  const searchInsideBanner = useMemo(() => {
    if (banners.length > 0) {
      return isSearchInsideBanner
    } else if (banners.length === 0) {
      return isSearchInsideDefaultBanner
    }
  }, [banners.length, isSearchInsideBanner, isSearchInsideDefaultBanner])

  function formatPosition(position?: string) {
    if (!position) {
      return {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }
    }

    let [justifyContent, alignItems] = position.split('/')

    if (!justifyContent) {
      justifyContent = 'center'
    }
    if (!alignItems) {
      alignItems = 'center'
    }
    const textAlign: any = alignItems

    justifyContent = justifyContent
      .replace('top', 'flex-start')
      .replace('bottom', 'flex-end')
    alignItems = alignItems
      .replace('left', 'flex-start')
      .replace('right', 'flex-end')

    return { justifyContent, alignItems, textAlign }
  }

  useEffect(() => {
    clearInterval(interval.current)
    interval.current = setInterval(() => {
      handleNextBanner()
    }, 10000)

    return () => {
      clearInterval(interval.current)
    }
  }, [banners, handleNextBanner])

  return (
    <Container currentBanner={currentBanner}>
      <div className="banner-container">
        {banners.length === 0 && (
          <div className="banner default-banner">
            <div className="content">
              <h1>{bannerTitle || portal.name}</h1>
            </div>
          </div>
        )}
        {banners.map(banner => (
          <BannerItem
            key={banner.id}
            className="banner"
            imageUrl={banner.imageUrl}
            mainColor={banner.mainColor}
            shadowColor={banner.shadowColor}
            fillType={banner.fillType}
          >
            <div
              className={`content${
                isSearchInsideBanner ? ' search-inside-banner' : ''
              }`}
              style={{ ...formatPosition(banner.position) }}
            >
              {!!banner.title && <h2>{banner.title}</h2>}
              {!!banner.text && <p>{banner.text}</p>}
              {!!banner.button?.text && !!banner.button.url && (
                <BannerButton
                  href={banner.button.url}
                  backgroundColor={banner.button.color}
                  textColor={banner.button.textColor}
                >
                  {banner.button.text}
                </BannerButton>
              )}
            </div>
          </BannerItem>
        ))}

        {banners.length > 1 && (
          <>
            <PrevButton type="button" onClick={handlePrevBanner}>
              <FiChevronLeft />
            </PrevButton>

            <SlideBullets>
              {banners.map((banner, index) => (
                <div
                  key={banner.id}
                  onClick={() => setCurrentBanner(index)}
                  className={`bullet ${
                    currentBanner === index ? 'active' : ''
                  }`}
                ></div>
              ))}
            </SlideBullets>

            <NextButton type="button" onClick={handleNextBanner}>
              <FiChevronRight />
            </NextButton>
          </>
        )}
      </div>
      <SearchBar isInsideBanner={searchInsideBanner} />
    </Container>
  )
}
