import styled from 'styled-components'

export const Container = styled.div`
  max-width: 100rem;
  width: 90%;
  margin: 3.2rem auto 0;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
    color: var(--headline);
    font-weight: 500;
  }

  h2 {
    font-size: 3.2rem;
    color: var(--headline);
    font-weight: 500;
  }
`

interface IPortalListProps {
  itemsCount: number
}

export const PortalList = styled.div<IPortalListProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.6rem;
  margin: 3.2rem auto 0;

  button {
    max-height: 20rem;
    height: 20rem;
    max-width: 20rem;
    width: 20rem;
    border: none;
    background: var(--white);
    box-shadow: var(--shadow-card);
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;

    h3 {
      font-weight: 500;
    }

    img {
      display: block;
      width: 70%;
      margin: 0 auto;
      height: 5rem;
      object-fit: scale-down;
    }

    &:hover {
      gap: 2rem;
      transform: scale(1.05);
      background-color: var(--brand-light);
    }
  }
`
